// src/App.js

import React, { useState, useEffect } from 'react';
import { Users, Clock, Book, ThumbsUp } from 'lucide-react';
import AnimatedCTAButton from './AnimatedCTAButton';
import { COURSE_PRICE } from './constants'; // Import COURSE_PRICE

const CourseHeroSection = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  return (
    <div className="hero-section">
      <div className="background-design">
        <div className="grid-overlay"></div>
        <div className="gradient-overlay"></div>
      </div>

      <div className="container">
        <div className={`content ${isVisible ? 'content-visible' : ''}`}>
          <div className="text-content">
            <div className="icon-title-wrapper">
              <div className="glowing-icon-container">
                <img 
                  src="https://others.tevh.in/wp-content/uploads/2024/08/369702154_209504695430789_5217105877357685444_n-removebg-preview-1.png" 
                  alt="Course Icon" 
                  className="course-icon"
                />
              </div>
              <h1 className="title">Master Android Hacking with CRAX RAT</h1>
            </div>
            <h2 className="subtitle">
              Learn to ethically hack Android devices, bypass antivirus, and control Android 14. Hands-on tutorials with expert guidance!
            </h2>
          </div>
          
          <div className="main-content">
            <div className="video-container">
              <div className="video-wrapper">
                <div className={`video-overlay ${isVideoLoaded ? 'loaded' : ''}`}>
                  <div className="loading-spinner"></div>
                </div>
                <iframe 
                  src="https://www.youtube.com/embed/FGuXQDU7vLQ?si=hbiLWqAS_MOGPI9b" 
                  title="Course Preview Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  onLoad={() => setIsVideoLoaded(true)}
                  className="course-preview-video"
                />
              </div>
            </div>

            <div className="info-section">
              <div className="icon-container">
                <IconWithHover
                  icon={Users}
                  text="Pre-recorded Course"
                  isHovered={hoveredIcon === 'users'}
                  setHovered={() => setHoveredIcon('users')}
                  clearHovered={() => setHoveredIcon(null)}
                />
                <IconWithHover
                  icon={Clock}
                  text="Lifetime Access"
                  isHovered={hoveredIcon === 'clock'}
                  setHovered={() => setHoveredIcon('clock')}
                  clearHovered={() => setHoveredIcon(null)}
                />
                <IconWithHover
                  icon={Book}
                  text="Hindi Language"
                  isHovered={hoveredIcon === 'book'}
                  setHovered={() => setHoveredIcon('book')}
                  clearHovered={() => setHoveredIcon(null)}
                />
                <IconWithHover
                  icon={ThumbsUp}
                  text="Beginner Friendly"
                  isHovered={hoveredIcon === 'thumbsUp'}
                  setHovered={() => setHoveredIcon('thumbsUp')}
                  clearHovered={() => setHoveredIcon(null)}
                />
              </div>
              
              <AnimatedCTAButton 
                text={`Enroll Now - Only ₹${COURSE_PRICE}`} // Use COURSE_PRICE here
                href="https://web.tevh.in/crax-rat"
                className="course-cta-button"
              />
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .hero-section {
          background: #0a0a0a;
          color: white;
          min-height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          padding: 40px 20px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        }
        .background-design {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow: hidden;
        }
        .grid-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-image: 
            linear-gradient(to right, rgba(255, 0, 0, 0.05) 1px, transparent 1px),
            linear-gradient(to bottom, rgba(255, 0, 0, 0.05) 1px, transparent 1px);
          background-size: 30px 30px;
          z-index: 1;
          opacity: 0.5;
          animation: gridPulse 10s infinite alternate;
        }
        .gradient-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: 
            radial-gradient(circle at 20% 20%, rgba(255, 0, 0, 0.2) 0%, transparent 60%),
            radial-gradient(circle at 80% 80%, rgba(255, 0, 0, 0.1) 0%, transparent 40%);
          opacity: 0.7;
          z-index: 2;
          animation: gradientShift 15s infinite alternate;
        }
        .container {
          max-width: 1200px;
          width: 100%;
          margin: 0 auto;
          z-index: 3;
          position: relative;
        }
        .content {
          display: flex;
          flex-direction: column;
          gap: 40px;
          align-items: center;
          opacity: 0;
          transform: translateY(20px);
          transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        }
        .content-visible {
          opacity: 1;
          transform: translateY(0);
        }
        .text-content {
          text-align: center;
          max-width: 800px;
          margin-bottom: 30px;
        }
        .icon-title-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
          margin-bottom: 30px;
        }
        .glowing-icon-container {
          width: 150px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .glowing-icon-container::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 130%;
          height: 130%;
          background: rgba(255, 0, 0, 0.2);
          border-radius: 50%;
          filter: blur(20px);
          z-index: -1;
          animation: glowPulse 3s infinite alternate;
        }
        .course-icon {
          width: 100%;
          height: 100%;
          object-fit: contain;
          filter: drop-shadow(0 0 10px rgba(255, 0, 0, 0.7));
          animation: iconFloat 5s ease-in-out infinite;
        }
        .title {
          font-size: clamp(2.5rem, 5vw, 4rem);
          font-weight: 800;
          background: linear-gradient(45deg, #ff0000, #ff3333);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
          letter-spacing: -0.5px;
          animation: titlePulse 3s ease-in-out infinite;
        }
        .subtitle {
          font-size: clamp(1.1rem, 1.8vw, 1.3rem);
          color: #f0f0f0;
          font-weight: 300;
          line-height: 1.6;
          margin: 0 0 20px 0;
          max-width: 700px;
          margin-left: auto;
          margin-right: auto;
        }
        .main-content {
          display: flex;
          gap: 50px;
          width: 100%;
          justify-content: center;
          align-items: flex-start;
        }

        /* Video Container Styles */
        .video-container {
          flex: 1 1 50%;
          max-width: 550px;
          border-radius: 20px;
          overflow: hidden;
          box-shadow: 0 10px 30px rgba(255, 0, 0, 0.3), 0 0 0 2px rgba(255, 255, 255, 0.1);
          transition: all 0.4s ease;
          border: 3px solid #ff0000;
          position: relative;
          z-index: 2;
          background: rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(10px);
        }

        .video-wrapper {
          position: relative;
          padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
          height: 0;
          overflow: hidden;
        }

        .course-preview-video {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.4s ease;
        }

        .video-overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.7);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;
          transition: opacity 0.3s ease;
        }

        .video-overlay.loaded {
          opacity: 0;
          pointer-events: none;
        }

        .loading-spinner {
          width: 50px;
          height: 50px;
          border: 3px solid rgba(255, 0, 0, 0.3);
          border-radius: 50%;
          border-top-color: #ff0000;
          animation: spin 1s ease-in-out infinite;
        }

        .video-container:hover {
          transform: scale(1.03) translateY(-5px);
          box-shadow: 0 15px 40px rgba(255, 0, 0, 0.4), 0 0 0 2px rgba(255, 255, 255, 0.2);
        }

        .info-section {
          flex: 1 1 50%;
          display: flex;
          flex-direction: column;
          gap: 40px;
          max-width: 500px;
        }
        .icon-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 25px;
        }
        .course-cta-button {
          width: 87% !important;
          margin-top: 20px;
          min-width: 450px;
        }
        .course-cta-button :global(a) {
          width: 100% !important;
          font-size: clamp(16px, 2.5vw, 18px) !important;
          height: clamp(50px, 8vw, 60px) !important;
          border-radius: 12px !important;
          background-color: #ff0000 !important;
          box-shadow: 0 8px 16px rgba(255, 0, 0, 0.3) !important;
          transition: all 0.4s ease !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          text-transform: uppercase !important;
          font-weight: 700 !important;
          letter-spacing: 0.5px !important;
        }
        .course-cta-button :global(a:hover) {
          transform: translateY(-3px) scale(1.02) !important;
          box-shadow: 0 12px 24px rgba(255, 0, 0, 0.4) !important;
          background-color: #ff1a1a !important;
        }

        @keyframes spin {
          to { transform: rotate(360deg); }
        }
        @keyframes titlePulse {
          0%, 100% {
            opacity: 1;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
          }
          50% {
            opacity: 0.9;
            text-shadow: 2px 2px 8px rgba(255,0,0,0.3);
          }
        }
        @keyframes glowPulse {
          0% {
            opacity: 0.5;
            transform: translate(-50%, -50%) scale(1);
          }
          100% {
            opacity: 0.8;
            transform: translate(-50%, -50%) scale(1.1);
          }
        }
        @keyframes iconFloat {
          0%, 100% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px);
          }
        }
        @keyframes gridPulse {
          0% {
            opacity: 0.3;
            background-size: 30px 30px;
          }
          100% {
            opacity: 0.5;
            background-size: 35px 35px;
          }
        }
        @keyframes gradientShift {
          0% {
            background-position: 0% 0%;
          }
          100% {
            background-position: 100% 100%;
          }
        }

        @media (max-width: 1024px) {
          .main-content {
            flex-direction: column;
            align-items: center;
          }
          .video-container, .info-section {
            max-width: 100%;
            width: 100%;
          }
          .course-cta-button {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            min-width: 0px;
          }
        }
        @media (max-width: 768px) {
          .hero-section {
            padding: 30px 15px;
          }
          .glowing-icon-container {
            width: 120px;
            height: 120px;
          }
          .title {
            font-size: clamp(2rem, 4vw, 3rem);
          }
          .icon-container {
            grid-template-columns: 1fr;
          }
          .course-cta-button :global(a) {
            font-size: 16px !important;
            height: 50px !important;
          }
        }
        @media (max-width: 480px) {
          .course-cta-button :global(a) {
            font-size: 14px !important;
            height: 45px !important;
          }
        }
      `}</style>
    </div>
  );
};

const IconWithHover = ({ icon: Icon, text, isHovered, setHovered, clearHovered }) => (
  <div 
    className={`icon-wrapper ${isHovered ? 'icon-wrapper-hovered' : ''}`}
    onMouseEnter={setHovered}
    onMouseLeave={clearHovered}
  >
    <Icon className={`icon ${isHovered ? 'icon-hovered' : ''}`} />
    <span className={`icon-text ${isHovered ? 'icon-text-hovered' : ''}`}>{text}</span>
    <style jsx>{`
      .icon-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px;
        border-radius: 20px;
        background-color: rgba(255, 0, 0, 0.08);
        transition: all 0.4s ease;
        cursor: pointer;
        position: relative;
        z-index: 3;
        backdrop-filter: blur(10px);
      }
      .icon-wrapper-hovered {
        background-color: rgba(255, 0, 0, 0.2);
        transform: translateY(-5px);
        box-shadow: 0 10px 25px rgba(255, 0, 0, 0.4);
      }
      .icon {
        width: 40px;
        height: 40px;
        color: #ff4444;
        margin-bottom: 15px;
        transition: all 0.4s ease;
      }
      .icon-hovered {
        color: #ff6666;
        transform: scale(1.15) rotate(5deg);
      }
      .icon-text {
        font-size: 1.1rem;
        text-align: center;
        transition: all 0.3s ease;
        font-weight: 500;
      }
      .icon-text-hovered {
        color: #ff6666;
      }
    `}</style>
  </div>
);

export default CourseHeroSection;
