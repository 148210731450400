// src/CourseContentSection.js

import React, { useState } from 'react';
import { ChevronDown, Clock, PlayCircle, Users, Video, BookOpen, Check, Lock } from 'lucide-react';
import AnimatedCTAButton from './AnimatedCTAButton';
import { COURSE_PRICE } from './constants'; // Import only COURSE_PRICE

const CourseContentSection = () => {
  const [openModule, setOpenModule] = useState(0);

  const toggleModule = (index) => {
    setOpenModule(openModule === index ? -1 : index);
  };

  const ORIGINAL_PRICE = 2000; // Original price in INR
  const DISCOUNT_PERCENTAGE = 45; // Discount percentage

  const modules = [
    {
      title: "Introduction",
      lectures: [
        {
          title: "Welcome to CRAX RAT Hacking",
          duration: "5:00",
          description: "Explore CRAX RAT's capabilities and course objectives."
        }
      ]
    },
    {
      title: "Setup",
      lectures: [
        {
          title: "Install Java & APKTool: Essential Setup for CRAX RAT",
          duration: "18:00",
          description: "Install Java and APKTool for CRAX RAT."
        },
        {
          title: "Complete CRAX RAT Setup: Full Installation",
          duration: "12:00",
          description: "Install and configure CRAX RAT for hacking."
        },
        {
          title: "Setup VMware and Virtual Windows for Hacking",
          duration: "15:00",
          description: "Create a virtual environment with VMware for safe testing."
        }
      ]
    },
    {
      title: "Hacking Android Within the Network",
      lectures: [
        {
          title: "Hacking Android 14: Within Network",
          duration: "25:00",
          description: "Hack Android 14 devices on the same network using CRAX RAT."
        }
      ]
    },
    {
      title: "Hacking Outside the Network",
      lectures: [
        {
          title: "Port Forwarding: Method 1",
          duration: "18:00",
          description: "Basic port forwarding for remote access."
        },
        {
          title: "Port Forwarding: Method 2",
          duration: "22:00",
          description: "Advanced port forwarding for remote control."
        }
      ]
    },
    {
      title: "Binding Payloads for Control",
      lectures: [
        {
          title: "Auto-Bind Payload with CRAX RAT",
          duration: "15:00",
          description: "Automate payload binding with CRAX RAT."
        },
        {
          title: "Manual Binding Using CRAX Software",
          duration: "20:00",
          description: "Manual payload binding for greater flexibility."
        }
      ]
    },
    {
      title: "Making Your Malware Fully Undetectable (FUD)",
      lectures: [
        {
          title: "Make Payloads Undetectable: Bypass Google Play Protect & Phone Security",
          duration: "30:00",
          description: "Create FUD payloads to bypass Google Play Protect."
        },
        {
          title: "Make Payload Undetectable (Coming Soon)",
          duration: "TBA",
          description: "Advanced techniques for payload stealth (Coming Soon)."
        }
      ]
    },
    {
      title: "Post-Exploitation Mastery",
      lectures: [
        {
          title: "Post-Exploitation Part-1",
          duration: "20:00",
          description: "Maintain access and monitor hacked devices."
        },
        {
          title: "Post-Exploitation Part-2",
          duration: "25:00",
          description: "Extract data and ensure persistence."
        },
        {
          title: "Post-Exploitation Part-3",
          duration: "30:00",
          description: "Maximize control over compromised devices."
        }
      ]
    },
    {
      title: "Advanced Tools & Exploits",
      lectures: [
        {
          title: "Dropper Techniques for Exploiting Accessibility",
          duration: "22:00",
          description: "Exploit Android accessibility features with droppers."
        },
        {
          title: "Uninstall Malware to Protect Yourself from Being Hacked",
          duration: "18:00",
          description: "Remove malware to protect your devices."
        },
        {
          title: "Automating Payload Installation",
          duration: "20:00",
          description: "Automate payload installation on target devices."
        }
      ]
    }
  ];

  return (
    <div className="course-content-section">
      <h2 className="section-title">Course Content</h2>
      <div className="content-wrapper">
        <div className="accordion">
          {modules.map((module, index) => (
            <div key={index} className={`accordion-item ${openModule === index ? 'open' : ''}`}>
              <div className="accordion-header" onClick={() => toggleModule(index)}>
                <div className="header-left">
                  <ChevronDown className={`chevron-icon ${openModule === index ? 'rotated' : ''}`} />
                  <h3 className="module-title">{module.title}</h3>
                </div>
                <div className="header-right">
                  <span className="lecture-count">{module.lectures.length} lectures</span>
                  <span className="module-number">Module {index + 1}</span>
                </div>
              </div>
              <div className="accordion-content">
                {module.lectures.map((lecture, lectureIndex) => (
                  <div key={lectureIndex} className="lecture-item">
                    <div className="lecture-main">
                      <div className="lecture-icon-title">
                        <PlayCircle className="play-icon" />
                        <span className="lecture-title">{lecture.title}</span>
                      </div>
                      <span className="lecture-description">{lecture.description}</span>
                    </div>
                    <div className="lecture-meta">
                      <Clock className="clock-icon" />
                      <span className="lecture-duration">{lecture.duration}</span>
                      <Lock className="lock-icon" />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
        <div className="course-sidebar">
          <div className="sidebar-content">
            <img src="https://others.tevh.in/wp-content/uploads/2024/09/compressed_banner.jpg" alt="Course thumbnail" className="course-thumbnail" />
            <div className="price-container">
              <span className="original-price">₹{ORIGINAL_PRICE}</span>
              <span className="discounted-price">₹{COURSE_PRICE}</span>
              <span className="discount-percentage">{DISCOUNT_PERCENTAGE}% OFF</span>
            </div>
            <AnimatedCTAButton 
              text={`Enroll Now`} // Dynamic enrollment button text
              href="https://web.tevh.in/crax-rat" 
              className="enroll-button"
            />
            <div className="course-highlights">
              <div className="highlight-item">
                <BookOpen className="highlight-icon" />
                <span><strong>8 Modules</strong> of intensive training</span>
              </div>
              <div className="highlight-item">
                <Video className="highlight-icon" />
                <span><strong>4+ Hours</strong> of high-quality content</span>
              </div>
              <div className="highlight-item">
                <Users className="highlight-icon" />
                <span><strong>Be the first</strong> to master CRAX RAT</span>
              </div>
            </div>
            <div className="course-features">
              <h4>What you'll get:</h4>
              <ul>
                <li><Check className="check-icon" /> Full lifetime access</li>
                <li><Check className="check-icon" /> Learn at your own pace</li>
                <li><Check className="check-icon" /> Mobile and TV access</li>
                <li><Check className="check-icon" /> Certificate of completion</li>
                <li><Check className="check-icon" /> Downloadable resources</li>
                <li><Check className="check-icon" /> 24/7 support</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .course-content-section {
          background-color: #0a0a0a;
          color: #ffffff;
          padding: 5rem 1rem;
          font-family: 'Poppins', sans-serif;
        }
        .section-title {
          font-size: clamp(2rem, 5vw, 3rem);
          font-weight: 700;
          margin-bottom: 3rem;
          text-align: center;
          color: #ff4444;
          text-shadow: 0 0 10px rgba(255, 68, 68, 0.3);
        }
        .content-wrapper {
          display: flex;
          flex-wrap: wrap;
          max-width: 1400px;
          margin: 0 auto;
          gap: 3rem;
          justify-content: center;
        }
        .accordion {
          flex: 1 1 600px;
          max-width: 800px;
        }
        .accordion-item {
          background-color: #181818;
          border: 1px solid #333333;
          border-radius: 16px;
          margin-bottom: 1.5rem;
          overflow: hidden;
          transition: all 0.3s ease;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        }
        .accordion-item.open {
          box-shadow: 0 8px 30px rgba(255, 68, 68, 0.2);
        }
        .accordion-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5rem;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }
        .accordion-header:hover {
          background-color: #222222;
        }
        .header-left, .header-right {
          display: flex;
          align-items: center;
        }
        .chevron-icon {
          width: 24px;
          height: 24px;
          margin-right: 1rem;
          transition: transform 0.3s ease;
          color: #ff4444;
        }
        .chevron-icon.rotated {
          transform: rotate(180deg);
        }
        .module-title {
          font-size: 1.125rem;
          font-weight: 600;
        }
        .lecture-count {
          font-size: 0.875rem;
          color: #9d9d9f;
          margin-right: 1rem;
        }
        .module-number {
          font-size: 0.875rem;
          color: #ff4444;
          padding: 0.25rem 0.625rem;
          border: 1px solid #ff4444;
          border-radius: 20px;
          background-color: rgba(255, 68, 68, 0.1);
        }
        .accordion-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease;
        }
        .open .accordion-content {
          max-height: 2000px;
        }
        .lecture-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1rem 1.5rem;
          border-top: 1px solid #333333;
          transition: background-color 0.3s ease;
        }
        .lecture-item:hover {
          background-color: #222222;
        }
        .lecture-main {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
        .lecture-icon-title {
          display: flex;
          align-items: center;
          margin-bottom: 0.25rem;
        }
        .lecture-meta {
          display: flex;
          align-items: center;
          min-width: 100px;
          justify-content: flex-end;
        }
        .play-icon, .clock-icon, .lock-icon {
          width: 18px;
          height: 18px;
          margin-right: 0.75rem;
          color: #ff4444;
        }
        .lecture-title {
          font-size: 1rem;
          font-weight: 500;
          color: #ffffff;
        }
        .lecture-description {
          font-size: 0.875rem;
          color: #9d9d9f;
          margin-left: 1.875rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .lecture-duration {
          font-size: 0.875rem;
          color: #9d9d9f;
          margin-right: 0.75rem;
        }
        .course-sidebar {
          flex: 1 1 300px;
          max-width: 380px;
        }
        .sidebar-content {
          background-color: #181818;
          border-radius: 20px;
          padding: 2rem;
          position: sticky;
          top: 20px;
          box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
        }
        .course-thumbnail {
          width: 100%;
          border-radius: 12px;
          margin-bottom: 1.5rem;
        }
        .price-container {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          margin-bottom: 1.5rem;
          flex-wrap: wrap;
        }
        .original-price {
          font-size: 1.25rem;
          color: #9d9d9f;
          text-decoration: line-through;
        }
        .discounted-price {
          font-size: 2rem;
          font-weight: 700;
          color: #31D36C;
        }
        .discount-percentage {
          font-size: 1rem;
          font-weight: 600;
          color: #ff4444;
          background-color: rgba(255, 68, 68, 0.1);
          padding: 0.25rem 0.625rem;
          border-radius: 20px;
        }
        .enroll-button {
          width: 100% !important;
          max-width: 85% !important;
          height: auto !important;
          min-height: 3rem !important;
          font-size: 1.125rem !important;
          padding: 0.75rem 1.5rem !important;
          margin-bottom: 1.875rem;
          border-radius: 12px !important;
          font-weight: 600 !important;
          text-transform: uppercase;
          letter-spacing: 0.5px;
          transition: all 0.3s ease !important;
          box-shadow: 0 8px 20px rgba(255, 68, 68, 0.3) !important;
        }
        .enroll-button:hover {
          transform: translateY(-3px) !important;
          box-shadow: 0 12px 28px rgba(255, 68, 68, 0.4) !important;
        }
        .course-highlights {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1.875rem;
          background-color: #222222;
          border-radius: 12px;
          padding: 1.25rem;
        }
        .highlight-item {
          display: flex;
          align-items: center;
          color: #ffffff;
        }
        .highlight-icon {
          width: 24px;
          height: 24px;
          margin-right: 1rem;
          color: #ff4444;
        }
        .course-features h4 {
          font-size: 1.25rem;
          margin-bottom: 1.25rem;
          color: #ff4444;
        }
        .course-features ul {
          list-style-type: none;
          padding: 0;
        }
        .course-features li {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          color: #ffffff;
        }
        .check-icon {
          width: 20px;
          height: 20px;
          margin-right: 1rem;
          color: #31D36C;
        }

        @media (max-width: 1200px) {
          .content-wrapper {
            flex-direction: column;
            align-items: center;
          }
          .accordion, .course-sidebar {
            max-width: 100%;
          }
          .sidebar-content {
            position: static;
          }
        }

        @media (max-width: 768px) {
          .course-content-section {
            padding: 3rem 1rem;
          }
          .section-title {
            margin-bottom: 2rem;
          }
          .accordion-header {
            flex-direction: column;
            align-items: flex-start;
          }
          .header-right {
            margin-top: 0.75rem;
          }
          .lecture-item {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
          }
          .lecture-meta {
            margin-top: 0.5rem;
            justify-content: flex-start;
          }
          .lecture-description {
            margin-top: 0.25rem;
            margin-left: 0;
          }
          .price-container {
            flex-wrap: wrap;
          }
          .course-highlights {
            padding: 1rem;
          }
        }

        @media (max-width: 480px) {
          .accordion-header {
            padding: 1rem;
          }
          .chevron-icon {
            width: 20px;
            height: 20px;
            margin-right: 0.75rem;
          }
          .module-title {
            font-size: 1rem;
          }
          .lecture-count, .module-number {
            font-size: 0.75rem;
          }
          .lecture-item {
            padding: 0.75rem 1rem;
          }
          .lecture-title {
            font-size: 0.875rem;
          }
          .lecture-description {
            font-size: 0.75rem;
          }
          .play-icon, .clock-icon, .lock-icon {
            width: 16px;
            height: 16px;
            margin-right: 0.625rem;
          }
          .lecture-duration {
            font-size: 0.75rem;
          }
          .sidebar-content {
            padding: 1.25rem;
          }
          .price-container {
            gap: 0.75rem;
          }
          .original-price {
            font-size: 1.125rem;
          }
          .discounted-price {
            font-size: 1.75rem;
          }
          .discount-percentage {
            font-size: 0.875rem;
          }
          .enroll-button {
            font-size: 1rem !important;
            padding: 0.625rem 1.25rem !important;
          }
          .course-highlights {
            padding: 0.875rem;
          }
          .highlight-item {
            font-size: 0.875rem;
          }
          .course-features h4 {
            font-size: 1.125rem;
          }
          .course-features li {
            font-size: 0.875rem;
            margin-bottom: 0.75rem;
          }
        }
      `}</style>
    </div>
  );
};

export default CourseContentSection;
